import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/services/case.service';
import { ContactFormPage } from '../contact-form/contact-form.page';
import { IonNav } from '@ionic/angular';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.page.html',
  styleUrls: ['./personal-detail.page.scss'],
})
export class PersonalDetailPage implements OnInit {
  debtorDetailsSubscription: Subscription;
  debtorDetails;
  caseDetailsSubscription: Subscription;
  datepicker_date_format = 'DD/MM/YYYY';
  firstCaseId;
  fieldLabels: any = {};

  lateralClients = {
    lackfords: 'Lackfords',
  };
  currentClientName;
  constructor(
    private caseService: CaseService,
    private ionNav: IonNav,
    private authService: AuthService
  ) {}

  ngOnInit() {
    var hostname = window.location.hostname;
    var url_split = hostname.split('.');
    this.currentClientName = this.lateralClients[url_split[0]]
      ? this.lateralClients[url_split[0]]
      : 'Us';
    this.getDebtorDetails();
    this.getCaseDetails();
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
  }
  formatDate(inputDateString) {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    if (settings?.datepicker_date_format) {
      this.datepicker_date_format = settings.datepicker_date_format;
    }
    const inputDate = moment(inputDateString);
    const formattedDate = inputDate.format(this.datepicker_date_format);
    return formattedDate;
  }
  getCaseDetails() {
    this.caseDetailsSubscription = this.caseService
      .getCaseDetailsData()
      .subscribe((res: any) => {
        if (res.result) {
          const caseList = Object.values(res.data.caseList);
          this.firstCaseId = caseList[0]?.['id'];
        }
      });
  }
  async goToContactFormUpdate() {
    this.ionNav.push(ContactFormPage, {
      caseId: this.firstCaseId,
      placeholder: 'Enter profile details...',
      textLabel:
        'If you need to correct your contact information, please let us know in the space provided below.',
      formName: 'personal'
    });
  }
  async goToContactForm() {
    this.ionNav.push(ContactFormPage, { caseId: this.firstCaseId, formName: 'contact' });
  }
  getDebtorDetails() {
    this.debtorDetailsSubscription = this.authService
      .getdebtorDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.debtorDetails = res.data;
        }
      });
  }
  ngOnDestroy(): void {
    if (this.debtorDetailsSubscription) {
      this.debtorDetailsSubscription.unsubscribe();
    }
    if (this.caseDetailsSubscription) {
      this.caseDetailsSubscription.unsubscribe();
    }
  }

  goToContactus() {}
}
