import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, NavigationExtras } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndexGuard implements CanActivate {
  constructor(public router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let token = localStorage.getItem('remote_token');
    if (token) {
      if (parseInt(localStorage.getItem('dynamicLogin')) && localStorage.getItem('default_webform_configured') == 'false') {
        this.router.navigate(['main/details'],{replaceUrl: true});
        return false;
      } else {
        let navigationExtras: NavigationExtras = {
          queryParams: {
            fslug: localStorage.getItem('form_slug'),
            cid: localStorage.getItem('case_id'),
            did: localStorage.getItem('debtor_id'),
            dpa: localStorage.getItem('dpa_check')
          },
          replaceUrl: true
        };
        this.router.navigate(['home'],navigationExtras);
        return false;
      }
    } else {
      return true;
    }
    
  }

}
