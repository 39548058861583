import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PaymentOptionPageRoutingModule } from './payment-option-routing.module';

import { PaymentOptionPage } from './payment-option.page';
import { MasterHeaderModule } from '../../master-header/master-header.module';
import { MasterFooterModule } from '../../master-footer/master-footer.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PaymentOptionPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [PaymentOptionPage],
})
export class PaymentOptionPageModule {}
