import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { USAEPAYCardPage } from './usaepay-card.page';

const routes: Routes = [
  {
    path: '',
    component: USAEPAYCardPage,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class USAEPAYCardPageRoutingModule {}
