import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CardService } from 'src/app/services/card.service';
import { CaseService } from 'src/app/services/case.service';
import { Subscription } from 'rxjs';
import { AddressDetailsPage } from '../../../../address-details/address-details.page';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-usaepay-ach',
  templateUrl: './usaepay-ach.page.html',
  styleUrls: ['./usaepay-ach.page.scss'],
})
export class USAEPayAchPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  amount;
  surcharge_amount;
  @Input() debtorId = localStorage.getItem('debtor_id');
  selectedCases = [];
  @Input() paymentMethod;
  addCardForm: FormGroup;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  savedCardList = [];
  debtorDetailsSubscription: Subscription;
  caseDetailsSubscription: Subscription;
  paymentGatewayList = [];
  savedCards = [];
  isGroupArrangement = false;
  billingAddress = [];
  selectedBillingAddress;
  saveBtnDisabled = false;

  paymentMethods = [
    { id: 1, label: 'Cash' },
    { id: 2, label: 'Cheque' },
    { id: 4, label: 'Credit card', name: 'card' },
    { id: 5, label: 'Debit Card', name: 'bank' },
    { id: 8, label: 'BACS' },
  ];
  method = null;
  outstanding;
  selectOptions = {};
  paymentGatewayTemplate;

  account_type_option = [{ id: 'checking', label: 'Checking' }];
  fieldLabels: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cardService: CardService,
    private caseService: CaseService,
    private modalController: ModalController,
    private ionNav: IonNav,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.amount = this.responseData?.net_amount;
    this.surcharge_amount = this.responseData?.surcharge_amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (
      portalSettings.payment_method_list &&
      Object.values(portalSettings.payment_method_list)
    ) {
      this.paymentMethods = [];
      const payment_method_list = portalSettings.payment_method_list;
      Object.keys(payment_method_list).forEach((key) => {
        this.paymentMethods.push({
          id: parseInt(key),
          label: payment_method_list[key],
        });
      });
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if(portalSettings?.gateway_templates?.usaepay?.ach_non_recurring_payment){
      this.paymentGatewayTemplate=portalSettings.gateway_templates.usaepay.ach_non_recurring_payment;
    }
    this.method = this.paymentMethods[0].id
      ? this.paymentMethods[0].id.toString()
      : '';
    this.initForm();
    this.getDebtorDetails();
    this.getCaseDetails();
  }
  async ionViewWillEnter() {
    this.getCardDetails();
  }
  getDebtorDetails() {
    this.debtorDetailsSubscription = this.authService
      .getdebtorDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.debtorDetails = res.data;
          this.updateFormData(res.data);
          this.billingAddress = res.data.addresses.map((add) => {
            return this.getBillingAddress(add);
          });
          if (this.billingAddress[0])
            this.selectedBillingAddress = this.billingAddress[0];
        }
      });
  }
  updateFormData(data) {
    const updatedValues = {
      first_name: data.debtor_1_name || '',
      last_name: data.debtor_1_surname || '',
      debtor_email: data.debtor_email || '',
      address_ln1: data?.addresses[0]?.address_ln1
        ? data.addresses[0]?.address_ln1
        : '',
      address_ln2: data?.addresses[0]?.address_ln2
        ? data.addresses[0]?.address_ln2
        : '',
      address_ln3: data?.addresses[0]?.address_ln3
        ? data.addresses[0]?.address_ln3
        : '',

      address_town: data?.addresses[0]?.address_town
        ? data.addresses[0]?.address_town
        : '',
      address_postcode: data?.addresses[0]?.address_postcode
        ? data.addresses[0]?.address_postcode
        : '',
    };
    Object.keys(updatedValues).forEach((key) => {
      this.addCardForm.controls[key].setValue(updatedValues[key]);
    });
  }
  removeBillingAddress() {
    this.selectedBillingAddress = null;
  }
  getCaseDetails() {
    this.caseDetailsSubscription = this.caseService
      .getCaseDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.paymentGatewayList = res.data.paymentGatewayList;
          this.addCardForm.controls.payment_method.setValue(
            this.paymentGatewayList[0].key.toString()
          );
          this.getCards();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.caseDetailsSubscription) {
      this.caseDetailsSubscription.unsubscribe();
    }
    if (this.debtorDetailsSubscription) {
      this.debtorDetailsSubscription.unsubscribe();
    }
  }

  getCardDetails() {
    if (this.paymentMethod) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.selectedCases;
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.paymentMethod, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element] });
            });
            this.savedCardList = cards;
          }
        });
    }
  }
  initForm() {
    this.addCardForm = this.formBuilder.group({
      bank_routing_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{9,20}$')],
      ],
      bank_account_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{9,20}$')],
      ],
      account_type: ['', [Validators.required]],
      debtor_email: [
        this.debtorDetails?.debtor_email ? this.debtorDetails.debtor_email : '',
        [Validators.required],
      ],
      first_name: [
        this.debtorDetails?.debtor_1_name
          ? this.debtorDetails.debtor_1_name
          : '',
        [Validators.required],
      ],
      last_name: [
        this.debtorDetails?.debtor_1_surname
          ? this.debtorDetails.debtor_1_surname
          : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        this.debtorDetails?.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_ln2: [
        this.debtorDetails?.addresses[0]?.address_ln2
          ? this.debtorDetails.addresses[0]?.address_ln2
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails?.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      address_town: [
        this.debtorDetails?.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [Validators.required],
      ],
      address_postcode: [
        this.debtorDetails?.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
      payment_method: [null, []], // done
      payment_card_list: [],
      selectedLinkCaseIds: [[]],
      method: [this.method, []],
    });
  }
  getBillingAddress(add) {
    const address_ln1 = add?.address_ln1 ? add?.address_ln1 + ', ' : '';
    const address_ln2 = add?.address_ln2 ? add?.address_ln2 + ', ' : '';
    const address_ln3 = add?.address_ln3 ? add?.address_ln3 + ', ' : '';
    const address_town = add?.address_town ? add?.address_town + ', ' : '';
    const address_postcode = add?.address_postcode ? add?.address_postcode : '';
    return (
      address_ln1 + address_ln2 + address_ln3 + address_town + address_postcode
    );
  }

  setLatestCard() {
    if (
      this.addCardForm.value.payment_method == 'sagePay' &&
      this.savedCards.length > 0
    ) {
      let payment_card_list = this.addCardForm.controls.payment_card_list;
      payment_card_list.setValue(
        this.savedCards[this.savedCards.length - 1].key
      );
    }
  }
  isAddressMissing() {
    const value = this.addCardForm.value;
    if (
      !value['address_town'] ||
      !value['address_postcode'] ||
      !value['address_ln1']
    )
      return true;
    return false;
  }
  async changeAddress() {
    const values = this.addCardForm.value;
    const address = {
      address_ln1: values.address_ln1,
      address_ln2: values.address_ln2,
      address_ln3: values.address_ln3,
      address_town: values.address_town,
      address_postcode: values.address_postcode,
    };
    const requiredAddress = {
      address_ln1: true,
      address_town: true,
      address_postcode: true,
    };
    const modal = await this.modalController.create({
      component: AddressDetailsPage,
      componentProps: {
        address,
        requiredAddress,
        setAddress: (data) => {
          Object.keys(data).forEach((key) => {
            this.addCardForm.controls[key].setValue(data[key]);
          });
          data['town'] = data['address_town'];
          data['payment_method'] = 'usaepay';
          data['amount'] = this.responseData.net_amount;
          data['surcharge_amount'] = this.responseData.surcharge_amount;
          this.caseService
            .reverifyGatewayPaymentFee(data)
            .subscribe((res : any) => {
              if(res.result) {
                this.amount = res.data.amount;
                this.surcharge_amount = res.data.surcharge_amount;
              }
            })
        },
      },
    });
    return await modal.present();
  }

  getCards() {
    if (this.addCardForm.value.payment_method) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.addCardForm.value.selectedLinkCaseIds;
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.addCardForm.value.payment_method, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element].last_digits });
            });
            this.savedCards = cards;
            setTimeout(() => {
              this.setLatestCard();
            }, 100);
          }
        });
    }
  }

  save() {
    this.addCardForm.markAllAsTouched();
    if (this.addCardForm.valid) {
      this.commonService.showLoader();
      const data = {
        gateway_id: this.responseData.id,
        amount: this.amount,
        description: this.addCardForm.value.description,
        routing: this.addCardForm.value.bank_routing_number,
        account: this.addCardForm.value.bank_account_number,
        account_type: this.addCardForm.value.account_type,
        debtor_first_name: this.addCardForm.value.first_name,
        debtor_last_name: this.addCardForm.value.last_name,
        address_ln1: this.addCardForm.value.address_ln1,
        address_ln2: this.addCardForm.value.address_ln2,
        address_ln3: this.addCardForm.value.address_ln3,
        town: this.addCardForm.value.address_town,
        address_postcode: this.addCardForm.value.address_postcode,
        debtor_id: this.debtorDetails.debtor_id,
      };
      this.caseService
        .makeUSAeAchRequest(this.caseId, data)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            this.commonService.showToast(
              'Action Performed Successfully!',
              'success'
            );
            this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
            this.ionNav.popToRoot();
          } else {
            this.commonService.showToast(res['message'], 'danger');
          }
        });
    } else {
      const keys = Object.keys(this.addCardForm.controls);
      for (let key of keys) {
        if (!this.addCardForm.controls[key].valid) {
          if (
            ['address_postcode', 'address_town', 'address_ln1'].includes(key)
          ) {
            this.commonService.showToast(
              `Address requires ${key} value, please update address!`,
              'danger'
            );
            return;
          }
          this.commonService.showToast(`Please check ${key} value!`, 'danger');
          return;
        }
      }
    }
  }
  async dismiss() {
    this.ionNav.pop();
  }
}
