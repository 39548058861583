import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ContactFormPageRoutingModule } from './contact-form-routing.module';

import { ContactFormPage } from './contact-form.page';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { MasterHeaderModule } from '../master-header/master-header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ContactFormPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [ContactFormPage],
})
export class ContactFormPageModule {}
