import { Component, Input, OnInit } from '@angular/core';
import { IonNav, AlertController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { PaymentFrequencyPage } from '../payment-frequency/payment-frequency.page';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.page.html',
  styleUrls: ['./payment-option.page.scss'],
})
export class PaymentOptionPage implements OnInit {
  @Input() caseData;
  @Input() debtorDetails;
  @Input() planType='plan';
  message = '';
  paymentOption = [
    {
      title: 'Pay in Full',
      label: 'Pay in full',
      desc: 'Pay your balance in full, 1 time, fast and easy.',
      type: 'payFull',
      iconName: 'card-outline',
    },
    {
      title: 'Payment Plan',
      label: 'Create payment plan',
      desc: 'Select a payment schedule that gives you options to pay over time.',
      type: 'paymentPlan',
      iconName: 'albums-outline',
    },
    {
      title: 'One-time Payment',
      label: 'Pay other amount',
      desc: 'Make a one time-payment for another amount.',
      type: 'oneTimePayment',
      iconName: 'time-outline',
    },
  ];

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav,
    private paymentService: PaymentService,
    private alertController: AlertController,
  ) {}

  ngOnInit() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    this.message = portalSettings.interest_message;
  }
  openAmountActionSheet(fullAmount) {
    this.paymentService.presentActionSheet(this.caseData, fullAmount);
  }

  handleOptionSelect(type) {
    if (type === 'paymentPlan') {
      this.caseService.setPaymentData({ paymentOption: type });
      this.ionNav.push(PaymentFrequencyPage, {
        caseData: this.caseData,
        planType: this.planType
      });
    } else if (type === 'payFull') {
      this.openAmountActionSheet(true);
    } else if (type === 'oneTimePayment') {
      this.openAmountActionSheet(false);
    }
  }
}
