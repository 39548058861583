import { Component, Input, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.page.html',
  styleUrls: ['./contact-form.page.scss'],
})
export class ContactFormPage implements OnInit {
  @Input() caseId;
  note: string;
  saveBtnDisabled = false;
  documentArray=[];
  fileUploadForm:FormGroup;
  file:File;
  @Input() textLabel: string =
    'Please send us a message below if you require any assistance with your account.';
  @Input() placeholder = 'Note';
  @Input() formName='contact';
  constructor(
    private modalCtrl: ModalController,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initForm();
  }
  initForm() {
    this.fileUploadForm = this.formBuilder.group({
      selectedFile: [null, Validators.required],
    });
  }
  async dismiss() {
    this.modalCtrl.dismiss({
      saved: false,
    });
  }
  cancel() {
    this.ionNav.pop();
  }
  save() {
    if (this.note) {
      this.saveBtnDisabled = true;
      const data = {
        note: this.note,
        caseid: this.caseId,
        attachments:[],
        type:this.formName
      };
      if(this.documentArray.length>0){
        data.attachments=this.documentArray;
      }
      this.caseService.contactForm(data).subscribe((res: any) => {
        if (res.result) {
          this.commonService.showToast(res['data']['message'], 'success');
          // this.modalCtrl.dismiss({
          //   saved: true,
          // });
          this.cancel();
        } else {
          this.commonService.showToast(res['message'], 'danger');
          // this.dismiss();
          this.cancel();
        }
        this.saveBtnDisabled = false;
      });
    }
  }
  onFileSelected(event:any){
    this.file=event.target.files[0];
  }
  async addDocuments(){
    this.documentArray.push(this.file);
    this.fileUploadForm.get('selectedFile').reset();
  }
  async deleteDocument(index){
    this.documentArray.splice(index, 1);
  }
}
