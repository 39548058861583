import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeGuard } from '../guards/home.guard';

import { MasterPage } from './master.page';

const routes: Routes = [
  {
    path: '',
    component: MasterPage,
    loadChildren: () => import('./master-home/master-home.module').then( m => m.MasterHomeModule),
    children: [
      {
        path: 'details',
        loadChildren: () =>
          import('./master-home/master-home.module').then(
            (m) => m.MasterHomeModule
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./master-home/master-home.module').then(
            (m) => m.MasterHomeModule
          ),
        // redirectTo: 'details',
        // pathMatch: 'full'
      },
      {
        path: 'payment-plan-request',
        loadChildren: () =>
          import('./payment-plan-request/payment-plan-request.module').then(
            (m) => m.PaymentPlanRequestPageModule
          ),
      },
      {
        path: 'contact-form',
        loadChildren: () =>
          import('./contact-form/contact-form.module').then(
            (m) => m.ContactFormPageModule
          ),
      },
    ],
  },
  { path: '**', redirectTo: 'details', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MasterPageRoutingModule {}
