import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { TrxServicesCardPage } from '../trxservices-card/trxservices-card.page';
import { TrxServicesAchPage } from '../trxservices-ach/trxservices-ach.page';
import { CaseService } from 'src/app/services/case.service';
@Component({
  selector: 'app-trxservices-method',
  templateUrl: './trxservices-method.page.html',
  styleUrls: ['./trxservices-method.page.scss'],
})
export class TrxServicesMethodPage implements OnInit {
  @Input() caseData;
  @Input() caseId;
  @Input() amount;
  @Input() totalOutstandingWithSymbol;
  @Input() debtorDetails;
  @Input() responseData;
  @Input() caseDetails;
  paymentMethod = [
    {
      title: 'Card payment',
      type: 'card',
      icon: 'card-outline',
      src: null,
    },
    {
      title: 'Ach payment',
      type: 'ach',
      icon: 'card-outline',
      src: 'assets/icon/ach2.svg',
    },
  ];

  constructor(private ionNav: IonNav,     private caseService: CaseService,
    ) {}

  ngOnInit() {
    let payment_type = {};
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
    }
    this.paymentMethod = this.paymentMethod.filter((method) => {
      if (method.type === 'ach') {
        return payment_type['usaepay'];
      }
      return true;
    });
    if(portalSettings?.payment_type?.trxServices?.options.length){
      const filteredMethods = this.paymentMethod.filter(method => {
        return portalSettings.payment_type.trxServices.options.includes(method.type);
      });
      this.paymentMethod=filteredMethods;
    }
  }

  handleMethodClick(type) {
    if (type === 'card') {
      this.caseService.makeTrxServicesPaymentRequest(this.caseId, { amount: this.responseData.net_amount, gatewayId : this.responseData.id, type : type })
      .subscribe(async (res: any) => {
        if (res.result) {
      this.ionNav.push(TrxServicesCardPage, {
        amount: this.amount,
        caseId: this.caseId,
        totalOutstandingWithSymbol: this.totalOutstandingWithSymbol,
        debtorDetails: this.debtorDetails,
        responseData: res.data,
        caseDetails: this.caseDetails,
      });
      }
      });
    } else if (type == 'ach') {
      this.caseService.makeTrxServicesPaymentRequest(this.caseId, { amount: this.responseData.net_amount, gatewayId : this.responseData.id, type : type })
      .subscribe(async (res: any) => {
        if (res.result) {
          this.ionNav.push(TrxServicesAchPage, {
            amount: this.amount,
            caseId: this.caseId,
            totalOutstandingWithSymbol: this.totalOutstandingWithSymbol,
            debtorDetails: this.debtorDetails,
            responseData: res.data,
            caseDetails: this.caseDetails,
          });
        }
      });
  }
}
}
