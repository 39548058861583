import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonNav, ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-mobipaid',
  templateUrl: './mobipaid.page.html',
  styleUrls: ['./mobipaid.page.scss'],
})
export class MobipaidPage implements OnInit {
  @Input() caseId;
  @Input() debtorDetails;
  @Input() caseData;
  @Input() totalAmount;
  payment_plan_amount = 0;
  isCustomPay = false;
  mobipaid_url;
  refreshBtnDisable = false;
  title = 'Lateral Payment Portal';

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.payment_plan_amount =
      this.caseData?.arranagement?.current_arrangement[0]?.amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.mobipaid && payment_type?.mobipaid?.label) {
        this.title = payment_type.mobipaid.label;
      }
    }
    this.isCustomPay =
      portalSettings?.payment_gateway_details?.mobipaid?.isCustom;
  }

  async getmobipaidurl(amount) {
    this.commonService.showLoader();
    this.refreshBtnDisable = true;
    let obj = {
      return_url: window.location.href,
      CaseID: this.caseId,
      amount: amount,
    };
    this.caseService.getMobipaidLink(obj).subscribe((res: any) => {
      if (res.result) {
        if (res.data?.payment_url) {
          var win = window.open(res.data.payment_url, '_self');
          if (win) {
            win.focus();
          }
        }
      } else {
        this.commonService.showToast(res['data']['error'], 'danger');
        this.commonService.dismissLoader();
        this.dismiss();
      }
    });
  }

  async dismiss() {
    this.ionNav.pop();
  }
  cancel() {
    this.dismiss();
  }

  async payCustomAmount() {
    const alert = await this.alertController.create({
      message: 'Enter the amount',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Continue',
          role: 'OK',
          handler: async (res) => {
            const amount = parseInt(res.amount, 10);
            await this.getmobipaidurl(amount);
          },
        },
      ],
      inputs: [
        {
          type: 'number',
          name: 'amount',
          placeholder: 'Amount...',
          value: this.totalAmount,
          min: 1,
        },
      ],
    });
    await alert.present();
  }
}
