import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { AuthService } from '../../services/auth.service';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/services/case.service';
import { MenuOptionComponent } from './menu-option/menu-option.page';

@Component({
  selector: 'master-header',
  templateUrl: './master-header.page.html',
  styleUrls: ['./master-header.page.scss'],
})
export class MasterHeaderPage implements OnInit {
  title = 'Lateral Debtor Portal';
  logo_url = '';
  debtorDetails;
  debtorDetailsSubscription: Subscription;
  portalSettingsSubscription: Subscription;
  headerColor = '';
  @Input() showProfile: boolean | undefined = false;
  @Input() showBackButton: boolean | undefined = true;
  @Input() goToPersonalDetail: (myNav) => void;
  @Input() showModalClose: boolean | undefined = false;

  constructor(
    private alertCtrl: AlertController,
    private authService: AuthService,
    private caseService: CaseService,
    private popoverController: PopoverController,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    if (localStorage.getItem('portal_settings')) {
      let settings = JSON.parse(localStorage.getItem('portal_settings'));
      if (settings?.portal_details?.title) {
        this.title = settings.portal_details.title;
      }
      if (settings?.portal_details?.logo_url) {
        this.logo_url = settings.portal_details.logo_url;
      }
      if (settings?.portal_details?.header_footer_colour_code) {
        this.headerColor = settings.portal_details.header_footer_colour_code;
      }
    }
    this.getDebtorDetails();
  }

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Logout!',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Logout',
          handler: () => {
            localStorage.removeItem('remote_token');
            let from_viewmycase = parseInt(
              localStorage.getItem('from_viewmycase')
            );
            if (from_viewmycase) {
              window.location.href = '/viewmycase';
            } else {
              window.location.href = '/login?dynamic=1';
            }
          },
        },
      ],
    });
    await alert.present();
  }
  ionViewWillEnter() {
    // this.getDebtorDetails();
  }
  getDebtorDetails() {
    this.debtorDetailsSubscription = this.authService
      .getdebtorDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.debtorDetails = res.data;
        }
      });
  }
  closeModal() {
    this.modalController.dismiss();
  }

  getUserName(userData) {
    // Checked for missing debtor name and surname to avoid errors.
    const debtor_1_name = userData.debtor_1_name?.trim();
    const debtor_1_surname = userData.debtor_1_surname?.trim();
    const tempName = (debtor_1_name ? debtor_1_name[0] : '') + (debtor_1_surname ? debtor_1_surname[0] : '');
    return tempName.toUpperCase();
  }

  ngOnDestroy(): void {
    if (this.debtorDetailsSubscription) {
      this.debtorDetailsSubscription.unsubscribe();
    }
    if (this.portalSettingsSubscription) {
      this.portalSettingsSubscription.unsubscribe();
    }
  }
  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: MenuOptionComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps: {
        goToPersonalDetail: this.goToPersonalDetail,
      },
    });
    await popover.present();

    const { role } = await popover.onDidDismiss();
    // console.log('onDidDismiss resolved with role', role);
  }
}
