import { Component, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { CaseService } from '../services/case.service';
import { MenuController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-master',
  templateUrl: './master.page.html',
  styleUrls: ['./master.page.scss'],
})
export class MasterPage implements OnInit {
  private timerSubscription: Subscription;
  title = 'Lateral Debtor Portal';
  logo_url = '';

  constructor(
    private alertCtrl: AlertController,
    private caseService: CaseService,
    private menuController: MenuController,
    private authService: AuthService
  ) {}

  ngOnInit() {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    if (settings?.portal_details?.title) {
      this.title = settings.portal_details.title;
    }
  }

  openMenu() {
    this.menuController.enable(true, 'menu');
    this.menuController.open('menu');
  }

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Logout!',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Logout',
          handler: () => {
            localStorage.removeItem('remote_token');
            let from_viewmycase = parseInt(
              localStorage.getItem('from_viewmycase')
            );
            if (from_viewmycase) {
              window.location.href = '/viewmycase';
            } else {
              window.location.href = '/login?dynamic=1';
            }
          },
        },
      ],
    });
    await alert.present();
  }
  ionViewWillEnter() {
    this.authService.saveDebtorDetails(localStorage.getItem('debtor_id'), true);
    this.timerSubscription = interval(1800000).subscribe(() => {
      this.authService.saveDebtorDetails(
        localStorage.getItem('debtor_id'),
        true
      );
    });
  }
  ionViewWillLeave() {
    this.timerSubscription.unsubscribe();
  }
}
