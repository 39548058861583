import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MobipaidPageRoutingModule } from './mobipaid-routing.module';

import { MobipaidPage } from './mobipaid.page';
import { MasterFooterModule } from '../../../master-footer/master-footer.module';
import { MasterHeaderModule } from '../../../master-header/master-header.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MobipaidPageRoutingModule,
    ReactiveFormsModule,
    MasterFooterModule,
    MasterHeaderModule,
  ],
  declarations: [MobipaidPage],
})
export class MobipaidPageModule {}
