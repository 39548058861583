import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import * as moment from 'moment';
import { PaymentGatewaysPage } from '../payment-gateways/payment-gateways.page';

@Component({
  selector: 'app-payment-date',
  templateUrl: './payment-date.page.html',
  styleUrls: ['./payment-date.page.scss'],
})
export class PaymentDatePage implements OnInit {
  @Input() caseData;
  @Input() planType;
  paymentFrequencyOption;
  selectedDate;
  datemin = moment().format('YYYY-MM-DD');

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    this.paymentFrequencyOption =
      this.caseService.paymentData.paymentFrequencyOption;
  }
  dateChanged() {
    if (this.selectedDate) {
      this.caseService.setPaymentData({
        dateType: null,
        date: this.selectedDate,
      });
      this.ionNav.push(PaymentGatewaysPage, { 
        caseData: this.caseData,
        planType: this.planType
      });
    } else {
      this.commonService.showToast('Please select date!', 'danger');
    }
  }
  dateTypeSelected(dateType) {
    let newDate = null;
    const currentDate = moment();
    if (dateType === '1st') {
      const firstDayOfMonth = moment(currentDate).startOf('month');
      if (currentDate.isAfter(firstDayOfMonth)) {
        newDate = moment(currentDate).add(1, 'month').startOf('month');
      } else {
        newDate = firstDayOfMonth;
      }
    } else if (dateType === '15th') {
      if (currentDate.date() <= 15) {
        currentDate.date(15);
      } else {
        currentDate.add(1, 'month').date(1);
      }
      newDate = currentDate;
    } else if (dateType === 'lastDayOfMnnth') {
      newDate = currentDate.endOf('month');
    }
    this.caseService.setPaymentData({ dateType, date: newDate });
    this.ionNav.push(PaymentGatewaysPage, { caseData: this.caseData, planType: this.planType });
  }
}
