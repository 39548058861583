import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MasterHeaderPage } from './master-header.page';
import { MenuOptionComponent } from './menu-option/menu-option.page';
@NgModule({
  declarations: [MasterHeaderPage, MenuOptionComponent],
  imports: [CommonModule, IonicModule],
  exports: [MasterHeaderPage, MenuOptionComponent],
})
export class MasterHeaderModule {}
