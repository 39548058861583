import { Component, Input, OnInit } from '@angular/core';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { ReinitService } from 'src/app/services/reinit.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'address-details',
  templateUrl: './address-details.page.html',
  styleUrls: ['./address-details.page.scss'],
})
export class AddressDetailsPage implements OnInit {
  addressForm: FormGroup;
  @Input() address;
  @Input() requiredAddress = {};
  @Input() setAddress;
  fieldLabels: any = {};
  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private modalController: ModalController,
    private commonService: CommonService,
    private reinitService: ReinitService
  ) {
    this.reinitService.reinit$.subscribe((shouldReinit) => {
      if (shouldReinit) {
        this.ionViewWillEnter(); // Manually trigger component reinitialization
      }
    });
  }

  ngOnInit() {
    this.initForm();
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
  }

  ionViewWillEnter() {}

  initForm() {
    this.addressForm = this.formBuilder.group({
      address_ln1: [
        this.address?.address_ln1 ? this.address?.address_ln1 : '',
        this.requiredAddress['address_ln1'] ? [Validators.required] : [],
      ],
      address_ln2: [
        this.address?.address_ln2 ? this.address?.address_ln2 : '',
        this.requiredAddress['address_ln2'] ? [Validators.required] : [],
      ],
      address_ln3: [
        this.address?.address_ln3 ? this.address?.address_ln3 : '',
        this.requiredAddress['address_ln3'] ? [Validators.required] : [],
      ],
      address_town: [
        this.address?.address_town ? this.address?.address_town : '',
        this.requiredAddress['address_town'] ? [Validators.required] : [],
      ],
      address_postcode: [
        this.address?.address_postcode ? this.address?.address_postcode : '',
        this.requiredAddress['address_postcode'] ? [Validators.required] : [],
      ],
      // address_area: [
      //   this.address?.address_area ? this.address?.address_area : '',
      //   this.requiredAddress['address_area'] ? [Validators.required] : [],
      // ],
    });
  }
  saveAddress() {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid) {
      const address = this.addressForm.value;
      this.caseService.setPaymentData({ address });
      this.setAddress(address);
      this.modalController.dismiss();
    } else {
      const keys = Object.keys(this.addressForm.controls);
      for (let key of keys) {
        if (!this.addressForm.controls[key].valid) {
          this.commonService.showToast(`Please check ${key} value!`, 'danger');
          return;
        }
      }
    }
  }

  ionViewDidEnter() {}
}
