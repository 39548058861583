import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CaseDetailPage } from './case-detail.page';
import { CaseDetailPageRoutingModule } from './case-detail-routing.module';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    CaseDetailPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [CaseDetailPage],
})
export class CaseDetailPageModule {}
