import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { TrxServicesMethodPageRoutingModule } from './trxservices-method-routing.module';

import { TrxServicesMethodPage } from './trxservices-method.page';
import { MasterHeaderModule } from '../../../../master-header/master-header.module';
import { MasterFooterModule } from '../../../../master-footer/master-footer.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    TrxServicesMethodPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [TrxServicesMethodPage],
})
export class TrxServicesMethodPageModule {}
