import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { CommonService } from '../services/common.service';
import { DynamicFormsService } from '../services/dynamic-forms.service';
@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  jsonObject: any;
  jsonString: any;
  formConfig: any;
  debtor_dob: '';
  loader;
  case_id: string;
  form_slug: string;
  debtor_id: string;
  dpa_check: string;
  formObj;
  title = 'Lateral Debtor Portal';

  constructor( 
    private dynamicFormsService: DynamicFormsService, 
    private alertCtrl: AlertController,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private router: Router

  ) { 
    this.case_id = this.form_slug = this.debtor_id = this.dpa_check = '';
  }

  ngOnInit() {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    if(settings?.portal_details?.title){
      this.title = settings.portal_details.title;
    }
    this.formObj = {};
    this.formConfig = {
      options: {
        alerts: {
          submitMessage: 'Thank you for submitting the form.'
        },
        errors: {
          message: 'Error while submitting the form. Please try again.'
        }
      }
    };
  }

  async ionViewWillEnter() {
    this.route.queryParams.subscribe(params => {
      if (params) {
        if(Object.keys(params).length > 0){
          this.dpa_check = params['dpa'];
          this.form_slug = params['fslug'];
          this.case_id = params['cid'];
          this.debtor_id = params['did'];
        }
      }
    });
    this.getForm();
  }

  getForm(){
    this.commonService.showLoader('Loading ...', 5000);
    this.dynamicFormsService.getForm(this.form_slug,this.case_id,this.debtor_id,this.dpa_check).subscribe(async(res)=>{
      this.commonService.dismissLoader();
      if(res['result']){
        if(res['data'].length > 0){
          this.setForm(res);
        }
      }else {
        this.commonService.showToast(res['message'],'danger');
      }
    });
  }

  setForm(obj){
    this.formObj = obj.data[0];
    this.jsonString = obj.data[0].content;
    this.jsonObject = JSON.parse(this.jsonString);
    if(this.jsonObject.display == 'form'){
			this.jsonObject.components.forEach( (component, index)=> {
        if(component.type == 'htmlelement'){
          var str = component.content;
          this.jsonObject.components[index].content = str.replace(/\\/g, ''); 
        }
      });
		} else if(this.jsonObject.display == 'wizard'){
      this.jsonObject.components.forEach( (page, index)=> {
        page.components.forEach((component,i) => {
          if(component.type == 'htmlelement'){
            var str = component.content;
            this.jsonObject.components[index].components[i].content = str.replace(/\\/g, ''); 
          }  
        });
      });
		}

  }

  onRender(event) {
    // console.log(event);
  }
  onNext(event) {
    // console.log(event);
  }
  async onChange(event) {
    // console.log(event)
  }

  async onSubmit(event) {
    this.commonService.showLoader();
    let formHtml = '';
    if(this.form_slug.includes('sign')){
      const formContainer = document.getElementById('myFormio');
      const submitButton = formContainer.querySelector('.btn.btn-primary[type="submit"]') as HTMLElement;
      submitButton.remove();
      formHtml = formContainer.innerHTML;
    }
    this.dynamicFormsService.saveFormData(this.form_slug,this.case_id,this.debtor_id,{data: event.data, formHtml: formHtml},this.dpa_check).subscribe(async (res) => {
      if(res['result']){
        this.jsonObject = {};
        this.commonService.showToast('Form Submitted!');
      }else {
        this.jsonObject = {};
        this.commonService.showToast('Failed to Submit!','danger');
      }
      this.commonService.dismissLoader();
    });
  }

  async confirmLogout() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Logout!',
      message: 'Are you sure you want to logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Logout',
          handler: () => {
            localStorage.setItem('remote_token','');
            // this.router.navigate(['/login']);
            window.location.href = '/login';
          }
        }
      ]
    });
    await alert.present();
  }
}
