import { Component, OnInit, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { AccountDetailsPage } from '../account-details/account-details.page';
import { PaymentService } from 'src/app/services/payment.service';
import { ContactFormPage } from '../contact-form/contact-form.page';

@Component({
  selector: 'master-home',
  templateUrl: './master-home.page.html',
  styleUrls: ['./master-home.page.scss'],
})
export class MasterHomePage implements OnInit {
  @ViewChild('myNav', { static: true }) myNav: IonNav;
  canShowContactUs = true;

  rootPage = AccountDetailsPage;

  constructor(
    private caseService: CaseService,
    private paymentService: PaymentService
  ) {}

  ngOnInit() {
    this.myNav.ionNavDidChange.subscribe(async () => {
      const activeComponentInstance = await this.myNav.getActive();
      if (activeComponentInstance.component == ContactFormPage) {
        this.canShowContactUs = false;
      } else {
        this.canShowContactUs = true;
      }
    });
    this.caseService.setMyNav(this.myNav);
    this.paymentService.onInit();
  }

  async showContactUs() {
    const stack = await this.myNav.getActive();
    if (stack.component != ContactFormPage)
      this.myNav.push(ContactFormPage, {
        caseId: this.caseService.firstCaseId,
      });
  }
}
