import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PaymentFrequencyPageRoutingModule } from './payment-frequency-routing.module';

import { PaymentFrequencyPage } from './payment-frequency.page';
import { MasterHeaderModule } from '../../master-header/master-header.module';
import { MasterFooterModule } from '../../master-footer/master-footer.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PaymentFrequencyPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [PaymentFrequencyPage],
})
export class PaymentFrequencyPageModule {}
