import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomPayOptionsPage } from '../custom-pay-options/custom-pay-options.page';
import { IonNav } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-custom-amount',
  templateUrl: './custom-amount.page.html',
  styleUrls: ['./custom-amount.page.scss'],
})
export class CustomAmountPage implements OnInit {
  @Input() caseData;
  @Input() planType = 'plan'; 
  @Input() total = 0;
  @Input() caseList = [];
  customAmountForm: FormGroup;
  constructor(private formBuilder:FormBuilder, private ionNav: IonNav, private commonService: CommonService) { }

  ngOnInit() {
    this.initForm()
  }
  initForm() {
    this.customAmountForm = this.formBuilder.group({
      amount: ['',[Validators.required,Validators.pattern('^[1-9][0-9]*$')]]
    });
  }
  save(){ 
    this.customAmountForm.markAllAsTouched();
    if(this.customAmountForm.valid){ 
      this.ionNav.push(CustomPayOptionsPage, { amount:this.customAmountForm.value.amount,caseData:this.caseData, planType: this.planType, total: this.total, caseList:this.caseList });
  }
  else{
    this.commonService.showToast('Invalid amount value', 'danger');
  }
}

}
