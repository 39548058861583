import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-communication-preferences',
  templateUrl: './communication-preferences.component.html',
  styleUrls: ['./communication-preferences.component.scss'],
})
export class CommunicationPreferencesComponent implements OnInit {
  isEmail = false;
  isSMS = false;
  debtorDetailsSubscription: Subscription;
  debtorEmail = '';
  debtorId = '';
  emails = [];
  emailItems = [];
  phoneItems = [];
  addEmail = false;
  addPhone = false;
  newEmail = '';
  isNewEmail = true;
  newPhone = '';
  isNewPhone = true;
  title = 'Lateral Debtor Portal';
  constructor(
    private modalController: ModalController,
    private authService: AuthService,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}
  ngOnInit() {
    this.getDebtorDetails();
      let settings = JSON.parse(localStorage.getItem('portal_settings'));
      if(settings?.portal_details?.title){
        this.title = settings.portal_details.title;
      }
  }

  cancel() {
    localStorage.setItem('isConsent', 'true');
    return this.modalController.dismiss();
  }

  getDebtorDetails() {
    this.debtorDetailsSubscription = this.authService
      .getdebtorDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.emails = res.data.emails;
          this.debtorId = res.data.debtor_id;
          this.emailItems = this.emails.map((ele) => ({
            type: 'email',
            id: ele.id,
            email: ele.account,
            sendEmail: !parseInt(ele.doNotSendEmail),
          }));

          this.emailItems.forEach((element) => {
            if (element.sendEmail) {
              this.isEmail = true;
            }
          });
          this.phoneItems = res.data.phone_numbers.map((ele) => ({
            type: 'telephone',
            id: ele.id,
            number: ele.number,
            isActive: !!parseInt(ele.isActive),
          }));

          this.phoneItems.forEach((element) => {
            if (element.isActive) {
              this.isSMS = true;
            }
          });
          if (res.data.debtor_email) {
            this.debtorEmail = res.data.debtor_email;
            this.isEmail = true;
            this.emailItems.push({
              type: 'debtor_email',
              id: this.debtorId,
              email: res.data.debtor_email,
              sendEmail: 1,
            });
          }
          if (res.data.debtor_phone) {
            this.isSMS = true;
            this.phoneItems.push({
              type: 'debtor_phone',
              id: res.data.debtor_id,
              number: res.data.debtor_phone,
              isActive: 1,
            });
          }
          if (res.data.debtor_mobile) {
            this.isSMS = true;
            this.phoneItems.push({
              type: 'debtor_mobile',
              id: res.data.debtor_id,
              number: res.data.debtor_mobile,
              isActive: 1,
            });
          }
        }
      });
  }

  submitPreferences() {
    if(this.isNewEmail && this.newEmail){
        this.emailItems.push({
        type: 'new_email',
        id: this.debtorId,
        email: this.newEmail,
        sendEmail: 1,
      });
    }
    if(this.isNewPhone && this.newPhone){
      this.phoneItems.push({
        type: 'new_phone',
        id: this.debtorId,
        number: this.newPhone,
        isActive: 1,
     });
   }
    if (!this.isEmail) {
      this.emailItems.forEach((item) => {
        item.sendEmail = false;
      });
    }
    if (!this.isSMS) {
      this.phoneItems.forEach((item) => {
        item.isActive = false;
      });
    }
    const data = [...this.phoneItems, ...this.emailItems];
    this.caseService.communicationPreferences(this.caseService.firstCaseId, data).subscribe((res: any) => {
      if (res && res.data?.result) {
        this.getDebtorDetails();
        this.commonService.showToast(res['data']['message'], 'success');
      } else {
        this.commonService.showToast(res['data']['message'], 'danger');
      }
    });

    localStorage.setItem('isConsent', 'true');
    this.modalController.dismiss();
  }
}
