import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IonDatetime } from '@ionic/angular';

@Component({
  selector: 'custom-input',
  templateUrl: './custom-input.page.html',
  styleUrls: ['./custom-input.page.scss'],
})
export class CustomInputComponent implements OnInit {
  @Input() props: any;
  @Input() classname: string = '';
  @Input() formgroup: FormGroup;
  @Input() formcontrolname: string;
  @Input() ionInput: boolean = false;
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() placeholder: string = '';
  @Input() min: string;
  @Input() max: string;
  @Input() displayFormat: string = 'MM/YY';
  @Input() pickerFormat: string = 'MM YYYY';
  @ViewChild('ionDateTime') ionDateTime: IonDatetime;
  @Input() interfaceoptions: Object = {};
  @Input() disabled = false;

  constructor() {}

  ngOnInit() {}
  labelClick(type) {
    if (type == 'date') this.ionDateTime.open();
  }

  isRequired() {
    return (
      this.formgroup.get(this.formcontrolname)?.validator &&
      this.formgroup.get(this.formcontrolname).validator({} as any)?.required
    );
  }
}
