import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';

import { PaymentDatePageRoutingModule } from './payment-date-routing.module';

import { PaymentDatePage } from './payment-date.page';
import { MasterHeaderModule } from '../../master-header/master-header.module';
import { MasterFooterModule } from '../../master-footer/master-footer.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PaymentDatePageRoutingModule,
    FormsModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [PaymentDatePage],
})
export class PaymentDatePageModule {}
