import { Component, OnInit, Input } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { PaymentDatePage } from '../payment-date/payment-date.page';

@Component({
  selector: 'app-custom-pay-options',
  templateUrl: './custom-pay-options.page.html',
  styleUrls: ['./custom-pay-options.page.scss'],
})
export class CustomPayOptionsPage implements OnInit {
  @Input() caseData;
  @Input() amount;
  @Input() planType = 'plan';
  @Input() total = 0;
  @Input() caseList = [];
  constructor(private caseService: CaseService, private ionNav: IonNav) {}

  ngOnInit() {}

  async mapToRequiredPaymentPlanOption(
    amount = 0,
    freq = '0',
    customPlan = 0,
    text = null,
    paymentFrequencyOption = null,
    scheduledAmount = '',
    limit = '1',
    total= 0,
    caseList = []
  ) {
    this.caseService.setPaymentData({
      paymentFrequencyOption,
      text,
      amount,
      scheduledAmount,
      freq,
      customPlan,
      limit,
      total,
      caseList
      
    });
  }
  save(option) {
    if (option == 'week') {
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        "3",
        0,
        "",
        'week',
        "0",
        '1',
        this.total,
        this.caseList
      );
      this.ionNav.push(PaymentDatePage, { caseData: this.caseData , planType: this.planType});
    } else if (option == 'fortnight') {
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        "6",
        0,
        "",
        'fortnight',
        "0",
        '1',
        this.total,
        this.caseList
      );
      this.ionNav.push(PaymentDatePage, { caseData: this.caseData , planType: this.planType});
    } else if (option == 'month') {
      this.mapToRequiredPaymentPlanOption(
        this.amount,
        '4',
        0,
        "",
        'month',
        "0",
        '1',
        this.total,
        this.caseList
      );
      this.ionNav.push(PaymentDatePage, { caseData: this.caseData , planType: this.planType});
    }
  }
}
