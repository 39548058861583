import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  var1 = this.authService.server_url;
  constructor(private authService: AuthService) {}
  ngOnInit() {
    var hostname = window.location.hostname;
    // // hostname = 'randb.forms.staging.omnicrm.co';
    // hostname = hostname.replace('forms.','');
    // var server_url = '';
    // if(hostname.includes('dcd.local') && (window.location.protocol == 'http:')){
    //   if(hostname == 'localhost'){
    //     hostname = 'randb.dcd.local';
    //     server_url = 'http://' + hostname + ':8081/';
    //   } else{
    //     server_url = 'http://' + hostname + ':8081/';
    //   }
    // } else {
    //   server_url = 'https://' + hostname + '/';
    // }

    var server_url = '';
    var staging_url = '.staging.omnicrm.co/';
    var omnicrm_staging_url = '.omnicrm.co/';
    var new_staging_url = '.staging.nodec.lateral1.com/';
    var new_live_url = '.nodec.lateral1.com/';
    var live_url = '.nodeb.lateral1.com/';
    var url_split = hostname.split('.');
    if (url_split[0] == 'resolvebcs' && hostname.includes('lbbd.gov.uk')) {
      url_split[0] = 'lbbd';
    }
    if (url_split[1] == 'staging' && url_split[2] == 'nodec') {
      server_url = 'https://' + url_split[0] + new_staging_url;
    } else if (url_split[1] == 'staging') {
      server_url = 'https://' + url_split[0] + staging_url;
    } else if (url_split[1] == 'omnicrm') {
      server_url = 'https://' + url_split[0] + omnicrm_staging_url;
    } else if (url_split[1] == 'nodec') {
      server_url = 'https://' + url_split[0] + new_live_url;
    } else {
      server_url = 'https://' + url_split[0] + live_url;
    }
    localStorage.setItem('server_url', server_url);
    this.authService.server_url = server_url;
    localStorage.getItem('portal_settings') &&
      this.authService.portalSettings.next({
        data: JSON.parse(localStorage.getItem('portal_settings')),
      });
  }
}
