import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MasterFooterPage } from './master-footer.page';
@NgModule({
  declarations: [MasterFooterPage],
  imports: [CommonModule, IonicModule],
  exports: [MasterFooterPage],
})
export class MasterFooterModule {}
