import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-stripepay',
  templateUrl: './stripepay.page.html',
  styleUrls: ['./stripepay.page.scss'],
})
export class StripepayPage implements OnInit {
  stripe;
  @Input() caseId;
  @Input() amount;
  @Input() currency_code;
  responseData;
  @Input() debtorDetails;
  @Input() totalOutstandingWithSymbol;
  @Input() caseDetails;
  title = 'Lateral Payment Portal';
  StripePayForm: FormGroup;
  stripePaymentResponse;
  saveBtnDisabled = true;
  usaepayclient;
  paymentCard;
  usaepay: any;
  jsUrl = '';
  publickey = '';
  isConfigured = true;
  fieldLabels : any = {};
  customerDetails: any = {};
  elements;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav
  ) {}

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }

  initStripeElements(clientSecret) {
    this.saveBtnDisabled = true;
    const appearance = { theme: 'stripe' };
    this.elements = this.stripe.elements({ appearance, clientSecret });
    const paymentElementOptions = { layout: 'tabs' };
    const paymentElement = this.elements.create(
      'payment',
      paymentElementOptions
    );
    paymentElement.mount('#payment-element');
    const addressElement = this.elements.create('address', { 
      mode: 'shipping',
      defaultValues: {
        name: (this.debtorDetails.debtor_1_name + ' ' + this.debtorDetails.debtor_1_surname).trim(),
      },
    });
    addressElement.mount('#address-element');
    addressElement.on('change', (event) => {
      if (event.complete){
        this.customerDetails = event.value;
      }
    })
    this.saveBtnDisabled = false;
  }

  async initialize() {
    let settings = JSON.parse(localStorage.getItem('portal_settings'));
    const stripePublishableKey =
      settings.payment_gateway_details.stripe.publickey;
    this.stripe = (window as any).Stripe(stripePublishableKey);
    this.caseService
      .makeStripePaymentRequest(this.caseId, {
        amount: this.amount,
        currency: this.currency_code,
      })
      .subscribe((response: any) => {
        this.responseData = response;
        const clientSecret = response.data.clientSecret;
        this.initStripeElements(clientSecret);
      });
  }

  async handleSubmit(e) {
    if(this.StripePayForm.valid){
      this.saveBtnDisabled = true;
      e.preventDefault();
      this.setLoading(true);

      const data = {
        request_type:'create_customer',
        payment_intent_id: this.responseData.data.card_auth_no,
        name: this.customerDetails.name,
        email: this.StripePayForm.value.email,
      };

      this.caseService.addStripeCustomer(data).subscribe(async (response: any) => {
        const { error, paymentIntent } = await this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: {
            // Make sure to change this to your payment completion page
            return_url: 'http://localhost:4200/main',
          },
        });
        console.log('err', error, 'ppp', paymentIntent);
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Otherwise, your customer will be redirected to
          // your `return_url`. For some payment methods like iDEAL, your customer will
          // be redirected to an intermediate site first to authorize the payment, then
          // redirected to the `return_url`.
          if (error.type === 'card_error' || error.type === 'validation_error') {
            this.showMessage(error.message);
          } else {
            this.showMessage('An unexpected error occurred.');
          }
        } else if (paymentIntent) {
          this.caseService
            .makeStripePayment(this.caseId, {
              gateway_id: this.responseData.data.id,
              amount: paymentIntent.amount,
              response: {
                status: paymentIntent.status,
                created: paymentIntent.created,
                id: paymentIntent.id,
                currency: paymentIntent.currency,
              },
            })
            .subscribe((res) => {
              console.log('res', res);
              this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
              this.ionNav.popToRoot();
            });
        }
        this.setLoading(false);
      })
    }
  }

  async checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret'
    );
    if (!clientSecret) {
      return;
    }
    const { paymentIntent } = await this.stripe.retrievePaymentIntent(
      clientSecret
    );
    switch (paymentIntent.status) {
      case 'succeeded':
        this.showMessage('Payment succeeded!');
        break;
      case 'processing':
        this.showMessage('Your payment is processing.');
        break;
      case 'requires_payment_method':
        this.showMessage('Your payment was not successful, please try again.');
        break;
      default:
        this.showMessage('Something went wrong.');
        break;
    }
  }

  // ------- UI helpers -------

  showMessage(messageText) {
    const messageContainer = document.querySelector('#payment-message');

    messageContainer.classList.remove('hidden');
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add('hidden');
      messageContainer.textContent = '';
    }, 4000);
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      (document.querySelector('#submit') as HTMLButtonElement).disabled = true;
      document.querySelector('#spinner').classList.remove('hidden');
      document.querySelector('#button-text').classList.add('hidden');
    } else {
      (document.querySelector('#submit') as HTMLButtonElement).disabled = false;
      document.querySelector('#spinner').classList.add('hidden');
      document.querySelector('#button-text').classList.remove('hidden');
    }
  }
  ngOnInit() {
    this.initialize();
    // this.amount = this.responseData?.net_amount;
    this.jsUrl = 'https://sandbox.usaepay.com/js/v1/pay.js';
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.usaepay && payment_type?.usaepay?.label) {
        this.title = payment_type.usaepay.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    if (
      portalSettings?.usaepay &&
      portalSettings?.usaepay?.jsUrl &&
      portalSettings?.usaepay?.publickey
    ) {
      this.jsUrl = portalSettings.usaepay.jsUrl;
      this.publickey = portalSettings.usaepay.publickey;
      this.isConfigured = true;
    }
    // this.loadScript(this.jsUrl)
    //   .then(() => {
    //     this.usaepayclient = new usaepay.Client(this.publickey);
    //     this.paymentCard = this.usaepayclient.createPaymentCardEntry();
    //     this.paymentCard.generateHTML();
    //     this.paymentCard.addHTML('paymentCardContainer');
    //     this.paymentCard.addEventListener('error', (errorMessage) => {
    //       var errorContainer = document.getElementById(
    //         'paymentCardErrorContainer'
    //       );
    //       errorContainer.textContent = errorMessage;
    //     });
    //     setTimeout(() => (this.saveBtnDisabled = false), 3000);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });

    this.initForm();
  }

  initForm() {
    const addresses = this.debtorDetails?.addresses.filter(
      (add) => add.address_main == 1
    ) || [{}];
    this.StripePayForm = this.formBuilder.group({
      email: [
        this.debtorDetails?.debtor_email
          ? this.debtorDetails?.debtor_email
          : '',
        [Validators.required],
      ],
    });
  }

  async dismiss() {
    this.ionNav.pop();
  }

  cancel() {
    this.dismiss();
  }
}
