import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { USAEPAYCardPageRoutingModule } from './usaepay-card-routing.module';

import { USAEPAYCardPage } from './usaepay-card.page';
import { MasterHeaderModule } from '../../../../master-header/master-header.module';
import { MasterFooterModule } from '../../../../master-footer/master-footer.module';
import { AddressDetailsPageModule } from '../../../../address-details/address-details.module';
import { CustomInputModule } from '../../../../common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    USAEPAYCardPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    AddressDetailsPageModule,
    CustomInputModule,
  ],
  declarations: [USAEPAYCardPage],
})
export class USAEPAYCardPageModule {}
