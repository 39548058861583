import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TrxServicesAchPageRoutingModule } from './trxservices-ach-routing.module';

import { TrxServicesAchPage } from './trxservices-ach.page';
import { MasterHeaderModule } from '../../../../master-header/master-header.module';
import { MasterFooterModule } from '../../../../master-footer/master-footer.module';
import { AddressDetailsPageModule } from '../../../../address-details/address-details.module';
import { CustomInputModule } from 'src/app/master/common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TrxServicesAchPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    AddressDetailsPageModule,
    CustomInputModule,
  ],
  declarations: [TrxServicesAchPage],
})
export class TrxServicesAchPageModule {}
