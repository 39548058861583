import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PaymentPlanRequestPageRoutingModule } from './payment-plan-request-routing.module';

import { PaymentPlanRequestPage } from './payment-plan-request.page';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { CustomInputModule } from '../common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MasterHeaderModule,
    MasterFooterModule,
    CustomInputModule,
    PaymentPlanRequestPageRoutingModule,
  ],
  declarations: [PaymentPlanRequestPage],
})
export class PaymentPlanRequestPageModule {}
