import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { StripepayPageRoutingModule } from './stripepay-routing.module';

import { StripepayPage } from './stripepay.page';
import { MasterHeaderModule } from '../../../master-header/master-header.module';
import { MasterFooterModule } from '../../../master-footer/master-footer.module';
import { CustomInputModule } from 'src/app/master/common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    StripepayPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    CustomInputModule,
  ],
  declarations: [StripepayPage],
})
export class StripepayPageModule {}
