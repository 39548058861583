import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PersonalDetailPage } from './personal-detail.page';
import { PersonalDetailPageRoutingModule } from './personal-detail-routing.module';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { MasterFooterModule } from '../master-footer/master-footer.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    PersonalDetailPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
  ],
  declarations: [PersonalDetailPage],
})
export class PersonalDetailPageModule {}
