import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonNav } from '@ionic/angular';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var payroc: any;

@Component({
  selector: 'app-payroc',
  templateUrl: './payroc.page.html',
  styleUrls: ['./payroc.page.scss'],
})
export class PayRocPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  title = 'Lateral Payment Portal';
  PayRocForm: FormGroup;
  saveBtnDisabled = false;
  isConfigured = false;
  amount = 0;
  fieldLabels = {};
  years: number[] = this.generateYearList();
  months: number[] = this.generateMonthList();
  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    this.saveBtnDisabled = false;
    this.amount = this.responseData?.net_amount;
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.payroc && payment_type?.payroc?.label) {
        this.title = payment_type.payroc.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    this.isConfigured = true;
    this.initForm();
  }

  initForm() {
    this.PayRocForm = this.formBuilder.group({
      amount: [
        { value: this.amount ? this.amount : 0, disabled: true },
        [Validators.required],
      ],
      card_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{12,20}$')],
      ],
      expiry_month: ['', [Validators.required]],
      expiry_year: ['', [Validators.required]],
    });
  }
  generateYearList(): number[] {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 16 }, (_, index) => currentYear + index);
    return years;
  }

  generateMonthList(): number[] {
    return Array.from({ length: 12 }, (_, index) => index + 1);
  }

  proceedPayment() {
    if (this.PayRocForm.valid) {
      this.saveBtnDisabled = true;
      let obj = this.PayRocForm.value;
      obj['gateway_id'] = this.responseData.id;
      this.caseService
        .makePayRocPayment(this.caseId, obj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            this.commonService.showToast(res['message'], 'success');
            this.ionNav.popToRoot();
          } else {
            this.commonService.showToast(res['message'], 'danger');
          }
        });
    }
  }

  async dismiss() {
    this.ionNav.pop();
  }
}
