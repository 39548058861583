import { Component, Input, OnInit } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { USAEPayAchPage } from '../usaepay-ach/usaepay-ach.page';
import { USAepayCardPage } from '../usaepay-card/usaepay-card.page';
@Component({
  selector: 'app-usaepay-method',
  templateUrl: './usaepay-method.page.html',
  styleUrls: ['./usaepay-method.page.scss'],
})
export class USAEPayMethodPage implements OnInit {
  @Input() caseData;
  @Input() caseId;
  @Input() amount;
  @Input() totalOutstandingWithSymbol;
  @Input() debtorDetails;
  @Input() responseData;
  @Input() caseDetails;
  paymentMethod = [
    {
      title: 'Card Payment',
      type: 'card',
      icon: 'card-outline',
      src: null,
    },
    {
      title: 'ACH Payment',
      type: 'ach',
      icon: 'card-outline',
      src: 'assets/icon/ach2.svg',
    },
  ];

  constructor(private ionNav: IonNav) {}

  ngOnInit() {
    let payment_type = {};
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
    }
    this.paymentMethod = this.paymentMethod.filter((method) => {
      if (method.type === 'ach') {
        return payment_type['usaepay'];
      }
      return true;
    });
    if(portalSettings?.payment_type?.usaepay?.options.length){
      const filteredMethods = this.paymentMethod.filter(method => {
        return portalSettings.payment_type.usaepay.options.includes(method.type);
      });
      this.paymentMethod=filteredMethods;
    }
  }

  handleMethodClick(type) {
    if (type === 'card') {
      this.ionNav.push(USAepayCardPage, {
        amount: this.amount,
        caseId: this.caseId,
        totalOutstandingWithSymbol: this.totalOutstandingWithSymbol,
        debtorDetails: this.debtorDetails,
        responseData: this.responseData,
        caseDetails: this.caseDetails,
      });
    } else if (type == 'ach') {
      this.ionNav.push(USAEPayAchPage, {
        amount: this.amount,
        caseId: this.caseId,
        totalOutstandingWithSymbol: this.totalOutstandingWithSymbol,
        debtorDetails: this.debtorDetails,
        responseData: this.responseData,
        caseDetails: this.caseDetails,
      });
    }
  }
}
