import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AddressDetailsPageRoutingModule } from './address-details-routing.module';
import { AddressDetailsPage } from './address-details.page';
import { MasterHeaderModule } from '../master-header/master-header.module';
import { MasterFooterModule } from '../master-footer/master-footer.module';
import { CustomInputModule } from '../common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    AddressDetailsPageRoutingModule,
    ReactiveFormsModule,
    MasterHeaderModule,
    MasterFooterModule,
    CustomInputModule
  ],
  declarations: [AddressDetailsPage],
})
export class AddressDetailsPageModule {}
