import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { CaseService } from './case.service';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  portalSettings = new BehaviorSubject(null);
  private portalSettings$ = this.portalSettings.asObservable();
  debtorDetails;
  debtorDetailsSB = new BehaviorSubject(null);
  private debtorDetails$ = this.debtorDetailsSB.asObservable();
  constructor(
    private http: HttpClient,
    private caseService: CaseService,
    private commonService: CommonService
  ) {}

  //login
  server_url = null;
  remote_token = null;
  case_id = null;
  debtor_id = null;
  default_webform_configured = null;
  dynamicLogin = null;
  form_slug = null;
  dpa_check = null;
  //login end

  authenticate(debtor_id, form_slug, dpa_check, dpa_pass_key) {
    const apiURL =
      localStorage.getItem('server_url') +
      'b/system/v1/session/debtor_login?dynamic=0';
    const data = {
      debtor_id: debtor_id,
      form_slug: form_slug,
      dpa_check: dpa_check,
      dpa_pass_key: dpa_pass_key,
    };
    return this.http.post(apiURL, data);
  }

  authenticate_direct(data) {
    const apiURL =
      localStorage.getItem('server_url') +
      'b/system/v1/session/debtor_login?dynamic=1';
    return this.http.post(apiURL, data);
  }

  portalSettings2() {
    const apiURL =
      localStorage.getItem('server_url') +
      'b/system/v1/session/debtor_login?fetchSettings=1';
    return this.http.post(apiURL, {});
  }

  getPortalSettings() {
    return this.portalSettings$;
  }

  getdebtorDetailsData() {
    return this.debtorDetails$;
  }

  saveDebtorDetails(debtor_id, getCaseData = false) {
    this.commonService.showLoader('Loading ...', 2000);
    const apiURL =
      localStorage.getItem('server_url') +
      `b/system/v3/debtor_api/get_debtor_details?debtor_id=${debtor_id}&source=debtor_API`;
    this.http.get(apiURL).subscribe((res: any) => {
      this.debtorDetails = res.data;
      this.debtorDetailsSB.next(res);
      this.commonService.dismissLoader();
      if (res.result) {
        this.caseService.saveCaseDetails(localStorage.getItem('debtor_id'));
      }
    });
  }

  savePortalSettings() {
    const apiURL =
      localStorage.getItem('server_url') +
      'b/system/v1/session/debtor_login?fetchSettings=1';
    return this.http.post(apiURL, {}).subscribe((res: any) => {
      //   this.portalSettings.next(res);
      this.portalSettings.next(res);
    });
  }
   
  getForgotLoginDetails(data){
      const apiURL =
        localStorage.getItem('server_url') +
        'b/system/v1/session/forgot_debtor_login';
      return this.http.post(apiURL, data);
  }
}
