import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CardService {
  constructor(private http: HttpClient) {}

  addCard(gateway, debtorId, data) {
    console.log(gateway, debtorId, data);
    let apiURL = '';
    if (gateway == 'usaepay') {
      apiURL =
        localStorage.getItem('server_url') +
        `b/legacy/debtor_panels/add_card_usaepay/${debtorId}?source=debtor_API`;
    }
    return this.http.post(apiURL, data);
  }

  getSavedCards(debtorId, gateway, type) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/payment/sage_pay_actions/get_card_list/${debtorId}/${gateway}/${type}?source=debtor_API`;
    return this.http.get(apiURL);
  }

  deleteCard(cardId,debtorId) {
    const apiURL =
      localStorage.getItem('server_url') +
      `b/legacy/payment_cards/delete/${cardId}/${debtorId}?source=debtor_API`;
    return this.http.get(apiURL);
  }
}
