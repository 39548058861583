import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { CommonService } from 'src/app/services/common.service';
import { CardService } from 'src/app/services/card.service';
import { CaseService } from 'src/app/services/case.service';
import { Subscription } from 'rxjs';
import { AddressDetailsPage } from '../../address-details/address-details.page';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-bank-payment',
  templateUrl: './bank-payment.page.html',
  styleUrls: ['./bank-payment.page.scss'],
})
export class BankPaymentPage implements OnInit {
  caseId;
  @Input() debtorId = localStorage.getItem('debtor_id');
  selectedCases = [];
  debtorDetails;
  @Input() paymentMethod;
  addCardForm: FormGroup;
  datemin = moment().format('YYYY-MM-DD');
  datemax = moment().add('100', 'years').format('YYYY-MM-DD');
  savedCardList = [];
  debtorDetailsSubscription: Subscription;
  caseDetailsSubscription: Subscription;
  paymentGatewayList = [];
  savedCards = [];
  isGroupArrangement = false;
  groupArrId;
  billingAddress = [];
  selectedBillingAddress;
  saveBtnDisabled = false;
  currentPageNumber = 1;
  title = 'Lateral Debtor Portal';

  amount; //add
  scheduledAmount; //add
  paymentFrequencyOption; //add
  limit; //add
  frequency; //add
  firstPaymentDate; //date
  paymentMethods = [
    { id: 1, label: 'Cash' },
    { id: 2, label: 'Cheque' },
    { id: 4, label: 'Credit card', name: 'card' },
    { id: 5, label: 'Debit Card', name: 'bank' },
    { id: 8, label: 'BACS' },
  ];
  method = null;
  outstanding;
  caseData;
  fieldLabels: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private cardService: CardService,
    private caseService: CaseService,
    private modalController: ModalController,
    private ionNav: IonNav,
    private authService: AuthService
  ) {}
  ngOnInit() {
    const paymentData = this.caseService.paymentData;
    this.caseId = paymentData.caseId;
    this.paymentMethod = paymentData.paymentMethod;
    this.amount = paymentData.amount;
    this.scheduledAmount = paymentData.scheduledAmount;
    this.frequency = paymentData.freq;
    this.firstPaymentDate = paymentData.date;
    this.paymentFrequencyOption = paymentData.paymentFrequencyOption;
    this.limit = paymentData.limit;
    this.caseData = paymentData.caseData;
    this.outstanding = this.caseData.d_outstanding.replace(
      this.caseData.currency_symbol,
      ''
    );
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.portal_details?.title) {
      this.title = portalSettings.portal_details.title;
    }
    if (
      portalSettings.payment_method_list &&
      Object.values(portalSettings.payment_method_list)
    ) {
      this.paymentMethods = [];
      const payment_method_list = portalSettings.payment_method_list;
      Object.keys(payment_method_list).forEach((key) => {
        this.paymentMethods.push({
          id: parseInt(key),
          label: payment_method_list[key],
        });
      });
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    this.method = this.paymentMethods[0].id
      ? this.paymentMethods[0].id.toString()
      : '';
    this.initForm();
    this.getDebtorDetails();
    this.getCaseDetails();
  }
  async ionViewWillEnter() {
    this.getCardDetails();
  }
  formatdate(date) {
    return moment(date).format('MM/DD/YYYY');
  }
  calculateLastDate() {
    let daterange = {
      month: 30,
      week: 7,
      fortnight: 15,
    };
    const addDate = daterange[this.paymentFrequencyOption] * this.limit;
    return moment(this.firstPaymentDate).add(addDate, 'days');
  }
  getDebtorDetails() {
    this.debtorDetailsSubscription = this.authService
      .getdebtorDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.debtorDetails = res.data;
          this.updateFormData(res.data);
          this.billingAddress = res.data.addresses.map((add) => {
            return this.getBillingAddress(add);
          });
          if (this.billingAddress[0])
            this.selectedBillingAddress = this.billingAddress[0];
        }
      });
  }
  updateFormData(data) {
    const updatedValues = {
      first_name: data.debtor_1_name || '',
      last_name: data.debtor_1_surname || '',
      debtor_email: data.debtor_email || '',
      address_ln1: data?.addresses[0]?.address_ln1
        ? data.addresses[0]?.address_ln1
        : '',
      address_ln2: data?.addresses[0]?.address_ln2
        ? data.addresses[0]?.address_ln2
        : '',
      address_ln3: data?.addresses[0]?.address_ln3
        ? data.addresses[0]?.address_ln3
        : '',

      address_town: data?.addresses[0]?.address_town
        ? data.addresses[0]?.address_town
        : '',
      address_postcode: data?.addresses[0]?.address_postcode
        ? data.addresses[0]?.address_postcode
        : '',
    };
    Object.keys(updatedValues).forEach((key) => {
      this.addCardForm.controls[key].setValue(updatedValues[key]);
    });
  }
  removeBillingAddress() {
    this.selectedBillingAddress = null;
  }
  getCaseDetails() {
    this.caseDetailsSubscription = this.caseService
      .getCaseDetailsData()
      .subscribe((res: any) => {
        if (res && res.result) {
          this.paymentGatewayList = res.data.paymentGatewayList;
          this.addCardForm.controls.payment_method.setValue(
            this.paymentGatewayList[0].key.toString()
          );
          // this.getCards();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.caseDetailsSubscription) {
      this.caseDetailsSubscription.unsubscribe();
    }
    if (this.debtorDetailsSubscription) {
      this.debtorDetailsSubscription.unsubscribe();
    }
  }

  getCardDetails() {
    if (this.paymentMethod) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.selectedCases;
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.paymentMethod, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element] });
            });
            this.savedCardList = cards;
          }
        });
    }
  }
  initForm() {
    this.addCardForm = this.formBuilder.group({
      isAuthorize: [false, [Validators.required]],
      institution_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{3,3}$')],
      ],
      transit_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{5,5}$')],
      ],
      account_number: [
        '',
        [Validators.required, Validators.pattern('^[0-9]{7,20}$')],
      ],
      bank_name: ['', [Validators.required]],
      bank_city: ['', [Validators.required]],
      bank_province: ['', [Validators.required]],
      debtor_email: [
        this.debtorDetails?.debtor_email ? this.debtorDetails.debtor_email : '',
        [Validators.required],
      ],
      debtor_telephone: [
        this.debtorDetails?.debtor_mobile
          ? this.debtorDetails.debtor_mobile
          : '',
        [Validators.required],
      ],
      first_name: [
        this.debtorDetails?.debtor_1_name
          ? this.debtorDetails.debtor_1_name
          : '',
        [Validators.required],
      ],
      last_name: [
        this.debtorDetails?.debtor_1_surname
          ? this.debtorDetails.debtor_1_surname
          : '',
        [Validators.required],
      ],
      description: [''],
      address_ln1: [
        this.debtorDetails?.addresses[0]?.address_ln1
          ? this.debtorDetails.addresses[0]?.address_ln1
          : '',
        [Validators.required],
      ],
      address_ln2: [
        this.debtorDetails?.addresses[0]?.address_ln2
          ? this.debtorDetails.addresses[0]?.address_ln2
          : '',
        [],
      ],
      address_ln3: [
        this.debtorDetails?.addresses[0]?.address_ln3
          ? this.debtorDetails.addresses[0]?.address_ln3
          : '',
        [],
      ],
      address_town: [
        this.debtorDetails?.addresses[0]?.address_town
          ? this.debtorDetails.addresses[0]?.address_town
          : '',
        [Validators.required],
      ],
      address_postcode: [
        this.debtorDetails?.addresses[0]?.address_postcode
          ? this.debtorDetails.addresses[0]?.address_postcode
          : '',
        [Validators.required],
      ],
      province: ['', []],
      repeatPayment: [true, []], //done
      payment_method: [null, []], // done
      payment_card_list: [],
      selectedLinkCaseIds: [[]],
      method: [this.method, []],
    });
  }
  getBillingAddress(add) {
    const address_ln1 = add?.address_ln1 ? add?.address_ln1 + ', ' : '';
    const address_ln2 = add?.address_ln2 ? add?.address_ln2 + ', ' : '';
    const address_ln3 = add?.address_ln3 ? add?.address_ln3 + ', ' : '';
    const address_town = add?.address_town ? add?.address_town + ', ' : '';
    const address_postcode = add?.address_postcode ? add?.address_postcode : '';
    return (
      address_ln1 + address_ln2 + address_ln3 + address_town + address_postcode
    );
  }

  setLatestCard() {
    if (
      this.addCardForm.value.payment_method == 'sagePay' &&
      this.savedCards.length > 0
    ) {
      let payment_card_list = this.addCardForm.controls.payment_card_list;
      payment_card_list.setValue(
        this.savedCards[this.savedCards.length - 1].key
      );
    }
  }
  isAddressMissing() {
    const value = this.addCardForm.value;
    if (
      !value['address_town'] ||
      !value['address_postcode'] ||
      !value['address_ln1']
    )
      return true;
    return false;
  }
  async changeAddress() {
    const values = this.addCardForm.value;
    const address = {
      address_ln1: values.address_ln1,
      address_ln2: values.address_ln2,
      address_ln3: values.address_ln3,
      address_town: values.address_town,
      address_postcode: values.address_postcode,
    };
    const requiredAddress = {
      address_ln1: true,
      address_town: true,
      address_postcode: true,
    };
    const modal = await this.modalController.create({
      component: AddressDetailsPage,
      componentProps: {
        address,
        requiredAddress,
        setAddress: (data) => {
          Object.keys(data).forEach((key) => {
            this.addCardForm.controls[key].setValue(data[key]);
          });
        },
      },
    });
    return await modal.present();
  }

  getCards() {
    if (this.addCardForm.value.payment_method) {
      let method = '';
      let id = this.debtorId;
      const linkedCases = this.addCardForm.value.selectedLinkCaseIds;
      let grpSelCases = '';
      if (linkedCases.length) {
        method = 'group';
        linkedCases.push(this.caseId);
        linkedCases.forEach((element) => {
          grpSelCases += element + '@@';
        });
        id = grpSelCases;
      }
      this.cardService
        .getSavedCards(id, this.addCardForm.value.payment_method, method)
        .subscribe((res) => {
          if (res) {
            const cards = [];
            Object.keys(res).forEach((element) => {
              cards.push({ key: element, value: res[element].last_digits });
            });
            this.savedCards = cards;
            setTimeout(() => {
              this.setLatestCard();
            }, 100);
          }
        });
    }
  }

  repeatPaymentSelected(event) {
    let payment_card_list = this.addCardForm.controls.payment_card_list;
    let payment_method = this.addCardForm.controls.payment_method;
    if (event.detail.checked) {
      payment_card_list.setValidators([Validators.required]);
      payment_card_list.updateValueAndValidity();
      payment_method.setValidators([Validators.required]);
      payment_method.updateValueAndValidity();
      if (this.paymentGatewayList.length > 0) {
        payment_method.setValue(this.paymentGatewayList[0].key);
        this.getCards();
      }
    } else {
      payment_card_list.clearValidators();
      payment_card_list.reset();
      payment_card_list.updateValueAndValidity();
      payment_method.clearValidators();
      payment_method.reset();
      payment_method.updateValueAndValidity();
    }
  }

  next() {
    this.addCardForm.markAllAsTouched();
    if (this.addCardForm.valid) {
      this.currentPageNumber = 2;
    } else {
      const keys = Object.keys(this.addCardForm.controls);
      for (let key of keys) {
        if (!this.addCardForm.controls[key].valid) {
          if (
            ['address_postcode', 'address_town', 'address_ln1'].includes(key)
          ) {
            this.commonService.showToast(
              `Address requires ${key} value, please update address!`,
              'danger'
            );
            return;
          }
          this.commonService.showToast(`Please check ${key} value!`, 'danger');
          return;
        }
      }
    }
  }
  back() {
    this.currentPageNumber = 1;
  }
  save() {
    this.addCardForm.markAllAsTouched();
    if (this.addCardForm.valid) {
      this.commonService.showLoader();
      this.saveBtnDisabled = true;
      const bankAccount = {
        account_number: this.addCardForm.value.account_number,
        transit_number: this.addCardForm.value.transit_number,
        institution_number: this.addCardForm.value.institution_number,
        bank_name: this.addCardForm.value.bank_name,
        bank_city: this.addCardForm.value.bank_city,
        bank_province: this.addCardForm.value.bank_province,
        debtor: {
          first_name: this.addCardForm.value.first_name,
          last_name: this.addCardForm.value.last_name,
          debtor_email: this.addCardForm.value.debtor_email,
          debtor_telephone: this.addCardForm.value.debtor_telephone,
          address: {
            address_ln1: this.addCardForm.value.address_ln1,
            address_ln2: this.addCardForm.value.address_ln2,
            address_ln3: this.addCardForm.value.address_ln3,
            town: this.addCardForm.value.address_town,
            address_postcode: this.addCardForm.value.address_postcode,
            province: this.addCardForm.value.province, //address_town
          },
        },
      };
      this.caseService
        .addBankAccount(this.debtorDetails.debtor_id, bankAccount)
        .subscribe((res: any) => {
          if (res.result) {
            const bankPaymentData = {
              freq: this.frequency,
              amount: this.amount ? this.amount : this.outstanding,
              ref_amount: this.outstanding,
              start: this.firstPaymentDate,
              mode: 'make',
              payment_method: 'bank_account',
              payment_card_list: res.data.id,
              method: this.addCardForm.value.method, //
              // source: 'this.arrangementForm.value.source', //
            };
            this.caseService
              .createArrangement('edit', this.caseId, bankPaymentData)
              .subscribe((res: any) => {
                this.saveBtnDisabled = false;
                this.commonService.dismissLoader();
                if (res.status == 200) {
                  this.commonService.showToast(
                    'Action Performed Successfully!',
                    'success'
                  );
                  this.caseService.saveCaseDetails(
                    localStorage.getItem('debtor_id')
                  );
                  this.ionNav.popToRoot();
                } else {
                  this.commonService.showToast(res['message'], 'danger');
                }
              });
          } else {
            this.saveBtnDisabled = true;
            this.commonService.dismissLoader();
          }
        });
    }
  }
}
