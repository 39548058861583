import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IonicModule } from '@ionic/angular';


import { MasterHeaderModule } from '../../master-header/master-header.module';
import { MasterFooterModule } from '../../master-footer/master-footer.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomAmountPage } from './custom-amount.page'
import { CustomInputModule } from '../../common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    MasterHeaderModule,
    MasterFooterModule,
    CustomInputModule,
  ],
  declarations: [CustomAmountPage],
})
export class CustomAmountPageModule {}
