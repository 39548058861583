import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'master-footer',
  templateUrl: './master-footer.page.html',
  styleUrls: ['./master-footer.page.scss'],
})
export class MasterFooterPage implements OnInit {
  footer_title = 'Lateral Technology';
  portalSettingsSubscription: Subscription;
  footerColour = '';

  constructor(
    private authService: AuthService,
    private caseService: CaseService
  ) {}

  ngOnInit() {
    if (localStorage.getItem('portal_settings')) {
      let settings = JSON.parse(localStorage.getItem('portal_settings'));
      if (settings?.portal_details?.footer_title) {
        this.footer_title = settings.portal_details.footer_title;
      }
      if (settings?.portal_details?.header_footer_colour_code) {
        this.footerColour = settings.portal_details.header_footer_colour_code;
      }
    }
  }
}
