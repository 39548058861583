import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReinitService {
  private reinitSubject = new BehaviorSubject<boolean>(false);

  reinit$ = this.reinitSubject.asObservable();

  triggerReinit() {
    this.reinitSubject.next(true);
  }
}
