import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonNav, IonicModule } from '@ionic/angular';

import { MasterHomePageRoutingModule } from './master-home-routing.module';

import { MasterHomePage } from './master-home.page';
import { MobipaidPageModule } from '../payment-gateway/non-recurring/mobipaid/mobipaid.module';
import { ContactFormPageModule } from '../contact-form/contact-form.module';
import { AccountDetailsPageModule } from '../account-details/account-details.module';
import { PaymentOptionPageModule } from '../payment-plan/payment-option/payment-option.module';
import { PaymentFrequencyPageModule } from '../payment-plan/payment-frequency/payment-frequency.module';
import { PaymentDatePageModule } from '../payment-plan/payment-date/payment-date.module';
import { PaymentMethodPageModule } from '../payment-plan/payment-method/payment-method.module';
import { USAEPAYCardPageModule } from '../payment-gateway/recurring/usaepay/usaepay-card/usaepay-card.module';
import { PersonalDetailPageModule } from '../personal-detail/personal-detail.module';
import { USAEPAYAchPageModule } from '../payment-gateway/recurring/usaepay/usaepay-ach/usaepay-ach.module';
import { CaseDetailPageModule } from '../case-detail/case-detail.module';
import { StripepayPageModule } from '../payment-gateway/non-recurring/stripepay/stripepay.module';
import { USAEPaymentMethodPageModule } from '../payment-gateway/non-recurring/usaepay/usaepay-method/usaepay-method.module';
import { USAEPayAchPageModule } from '../payment-gateway/non-recurring/usaepay/usaepay-ach/usaepay-ach.module';
import { USAepayCardPageModule } from '../payment-gateway/non-recurring/usaepay/usaepay-card/usaepay-card.module';
import { PayRocPageModule } from '../payment-gateway/non-recurring/payroc/payroc.module';
import { BankPaymentPageModule } from '../payment-plan/bank-payment/bank-payment.module';
import { PaymentGatewaysPageModule } from '../payment-plan/payment-gateways/payment-gateways.module';
import { PaymentPlanRequestPageModule } from '../payment-plan-request/payment-plan-request.module';
import { GoCardlessPageModule } from '../payment-gateway/non-recurring/gocardless/gocardless.module';
import { AcquiredPageModule } from '../payment-gateway/non-recurring/acquired/acquired.module';
import { TrxServicesCardPageModule } from '../payment-gateway/non-recurring/trxservices/trxservices-card/trxservices-card.module';
import { TrxServicesMethodPageModule} from '../payment-gateway/non-recurring/trxservices/trxservices-method/trxservices-method.module';
import { TrxServicesAchPageModule } from '../payment-gateway/non-recurring/trxservices/trxservices-ach/trxservices-ach.module';
import { CustomAmountPageModule } from '../payment-plan/custom-amount/custom-amount.module';
import { CustomPayOptionsPageModule } from '../payment-plan/custom-pay-options/custom-pay-options.module';
import { ViewPaymentPlanPageModule } from '../view-payment-plan/view-payment-plan.module';

import { TrxservicesCardPageModule } from '../payment-gateway/recurring/trxservices/trxservices-card/trxservices-card.module';
import { TrxservicesAchPageModule } from '../payment-gateway/recurring/trxservices/trxservices-ach/trxservices-ach.module';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [IonNav],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MasterHomePageRoutingModule,
    MobipaidPageModule,
    ContactFormPageModule,
    AccountDetailsPageModule,
    PaymentOptionPageModule,
    PaymentFrequencyPageModule,
    PaymentDatePageModule,
    PaymentMethodPageModule,
    USAEPAYCardPageModule,
    PersonalDetailPageModule,
    USAEPAYAchPageModule,
    CaseDetailPageModule,
    StripepayPageModule,
    USAEPaymentMethodPageModule,
    USAEPayAchPageModule,
    USAepayCardPageModule,
    PayRocPageModule,
    BankPaymentPageModule,
    PaymentGatewaysPageModule,
    PaymentPlanRequestPageModule,
    GoCardlessPageModule,
    AcquiredPageModule,
    TrxServicesMethodPageModule,
    TrxServicesCardPageModule,
    TrxServicesAchPageModule,
    CustomAmountPageModule,
    CustomPayOptionsPageModule,
    ViewPaymentPlanPageModule,

    TrxservicesCardPageModule,
    TrxservicesAchPageModule
  ],
  declarations: [MasterHomePage],
})
export class MasterHomeModule {}
