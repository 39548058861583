import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonNav, ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
declare var gocardless: any;

@Component({
  selector: 'app-gocardless',
  templateUrl: './gocardless.page.html',
  styleUrls: ['./gocardless.page.scss'],
})
export class GoCardlessPage implements OnInit {
  @Input() caseId;
  @Input() responseData;
  @Input() caseData;
  debtorDetails: any;
  type = 'edit';
  title = 'Lateral Payment Portal';
  gocardless: any;
  GoCardlessForm: FormGroup;
  saveBtnDisabled = false;
  isConfigured = false;
  amount = 0;
  is_iban = false;
  fieldLabels = {};
  constructor(
    private ionNav: IonNav,
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private authService: AuthService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.debtorDetails = this.authService.debtorDetails;
    this.saveBtnDisabled = false;
    const prePaymentData = this.caseService.paymentData;
    if(this.responseData) {
      this.amount = this.responseData?.net_amount;
    } else {
      this.amount = prePaymentData?.amount;
    }
    this.caseService.setPaymentData({
      ref_amount: this.caseData?.amount1,
      mode: 'make',
      repeatPayment: true,
    });
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      if (payment_type?.gocardless && payment_type?.gocardless?.label) {
        this.title = payment_type.gocardless.label;
      }
    }
    if (portalSettings?.fieldLabels) {
      this.fieldLabels = portalSettings.fieldLabels;
    }
    this.isConfigured = true;
    this.initForm();
  }

  initForm() {
    this.GoCardlessForm = this.formBuilder.group({
      debtor_first_name: [this.debtorDetails.debtor_1_name],
      debtor_last_name: [this.debtorDetails.debtor_1_surname],
      debtor_email: [this.debtorDetails.debtor_email],
      note: [],
      amount: [this.amount ? this.amount : 0, [Validators.required]],
      sort_code: ['', [Validators.required]],
      is_iban: false,
      account_number: ['', [Validators.required]],
    });
  }

  isIBANChecked(event) {
    let sort_code = this.GoCardlessForm.controls.sort_code;
    if (event.detail.checked) {
      this.is_iban = true;
      sort_code.setValidators([]);
      sort_code.updateValueAndValidity();
    } else {
      this.is_iban = false;
      sort_code.setValidators([Validators.required]);
      sort_code.updateValueAndValidity();
    }
  }

  proceedPayment() {
    if (this.GoCardlessForm.valid) {
      this.saveBtnDisabled = true;
      let obj = this.GoCardlessForm.value;
      if(this.responseData) {
        obj['gateway_id'] = this.responseData.id
      }
      if(this.responseData) {
        this.caseService
        .makeGoCardlessPayment(this.caseId, obj)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.result) {
            this.commonService.showToast(res['message'], 'success');
            this.ionNav.popToRoot();
          } else {
            this.commonService.showToast(res['message'], 'danger');
          }
        });
      } else {
        this.caseService.setPaymentData(this.GoCardlessForm.value);
        const paymentData = this.caseService.paymentData;
        this.caseService
        .createArrangement(this.type, this.caseData.id, paymentData)
        .subscribe((res: any) => {
          this.saveBtnDisabled = false;
          this.commonService.dismissLoader();
          if (res.status == 200) {
            this.commonService.showToast('Action Performed Successfully!', 'success');
            this.ionNav.popToRoot();
          } else {
            this.commonService.showToast(res['message'], 'danger');
            this.dismiss();
          }
        });
      }
    }
  }

  async dismiss() {
    this.ionNav.popToRoot();
  }
}
