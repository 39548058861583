import { Component, Input, OnInit } from '@angular/core';
import { CaseService } from 'src/app/services/case.service';
import { CommonService } from 'src/app/services/common.service';
import { IonNav } from '@ionic/angular';
import { PaymentMethodPage } from '../payment-method/payment-method.page';

@Component({
  selector: 'app-payment-gateways',
  templateUrl: './payment-gateways.page.html',
  styleUrls: ['./payment-gateways.page.scss'],
})
export class PaymentGatewaysPage implements OnInit {
  @Input() caseData;
  @Input() planType;
  paymentBtnDisabled = false;
  availablePaymentListArray = [];
  paymentMethod = [];
  defaultPaymentPortal = 'usaepay';
  paymentList: any = {
    mobipaid: {
      text: 'Mobipaid',
    },
    usaepay: {
      text: 'USA ePay',
    },
    govukpay: {
      text: 'GOV.UK Pay',
    },
    sagepay: {
      text: 'Sage Pay',
    },
    payroc: {
      text: 'Pay Roc',
    },
    stripe: {
      text: 'Stripe Pay',
    },
    gocardless: {
      text: 'GoCardless Pay',
    },
    trxServices : {
      text : 'EzPay / TRX Services'
    }
  };
  paymentGateways = [
    {
      title: 'Card payment',
      type: 'card',
      icon: 'card-outline',
      src: null,
    },
    {
      title: 'Ach payment',
      type: 'ach',
      icon: 'card-outline',
      src: 'assets/icon/ach2.svg',
    },
    {
      title: 'Bank payment',
      type: 'bank',
      icon: 'card-outline',
      src: 'assets/icon/bank2.svg',
    },
  ];

  constructor(
    private caseService: CaseService,
    private commonService: CommonService,
    private ionNav: IonNav
  ) {}

  ngOnInit() {
    this.checkAvailablePaymentList();
  }
  checkAvailablePaymentList() {
    let portalSettings = JSON.parse(localStorage.getItem('portal_settings'));
    if (portalSettings?.payment_type) {
      let payment_type = portalSettings?.payment_type
        ? portalSettings?.payment_type
        : {};
      this.availablePaymentListArray = [];
      Object.values(payment_type).forEach((value: any, index) => {
        if (index == 0) {
          this.defaultPaymentPortal = value.key;
        }
        if (this.paymentList[value.key]) {
          this.paymentList[value.key].text =
            value.label || this.paymentList[value.key].text;
          this.paymentList[value.key].value = value.key;
          this.availablePaymentListArray.push(this.paymentList[value.key]);
        }
      });
    }
    if (this.availablePaymentListArray.length == 0) {
      this.paymentBtnDisabled = true;
    }
  }

  handleGatewayClick(selectedGateway) {
    if (selectedGateway) {
      this.caseService.setPaymentData({ selectedGateway });
      this.ionNav.push(PaymentMethodPage, {
        caseData: this.caseData,
        planType: this.planType
      });
    } else {
      this.commonService.showToast('Please select gateway!', 'danger');
    }
  }
}
