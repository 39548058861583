import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { TrxservicesCardPageRoutingModule } from './trxservices-card-routing.module';

import { TrxservicesCardPage } from './trxservices-card.page';
import { MasterHeaderModule } from '../../../../master-header/master-header.module';
import { MasterFooterModule } from '../../../../master-footer/master-footer.module';
import { AddressDetailsPageModule } from '../../../../address-details/address-details.module';
import { CustomInputModule } from '../../../../common-components/custom-input/custom-input.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TrxservicesCardPageRoutingModule,
    MasterHeaderModule,
    MasterFooterModule,
    AddressDetailsPageModule,
    CustomInputModule,
  ],
  declarations: [TrxservicesCardPage],
})
export class TrxservicesCardPageModule {}
