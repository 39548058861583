import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-help-content',
  templateUrl: './help-content.component.html',
  styleUrls: ['./help-content.component.scss'],
})
export class HelpContentComponent implements OnInit {

  @Input() fieldKeys = [];
  @Input() fieldList = {};

  constructor() { }

  ngOnInit() {  }

}
